import React, { useState, useRef, useEffect } from 'react'
import tw, { styled, css } from 'twin.macro'
import { parseISO, format, differenceInYears, nextFriday } from 'date-fns'
import { FormControl, label, Select } from './FormControls'
import Button from './Button'
import useFbFunc from '../hooks/useFbFunc'
import { textTransform } from '../utils/textUtilities'

const overlay = css`
  ${tw`w-screen h-screen fixed inset-0 z-50 bg-default p-4 flex flex-col justify-center font-sans text-base`}
  backdrop-filter: blur(2px);
  --bg-opacity: 0.85;
`

const fullday = css`
  ${tw`bg-purple`}
`

const Row = styled.div`
  ${tw`flex space-x-8 justify-between items-center my-2`}
`

const DataEl = styled.div`
  ${tw`flex flex-col`}

  ${css`
    min-width: 20%;
  `}

  span {
    ${tw`block text-xs font-bold text-purple`}
  }
`

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        alert('You clicked outside of me!')
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const getAge = birthday => differenceInYears(new Date(), parseISO(birthday.substring(0, 10)))

const removeFalsy = obj => {
  const newObj = {}
  Object.keys(obj).forEach(prop => {
    if (obj[prop]) {
      newObj[prop] = obj[prop]
    }
  })
  return newObj
}

const weeksWithDaysSelected = obj => {
  const newObj = {}
  Object.entries(obj).forEach(week => {
    // Check if any of the days are set to true
    if (Object.values(week[1]).filter(day => day).length) {
      newObj[`${week[0]}`] = { ...week[1] }
    }
  })
  return newObj
}

const formatWeekDates = date =>
  `${format(parseISO(date), 'MMM d')} – ${format(nextFriday(parseISO(date)), 'MMM d, yyyy')}`

const StartWeekUi = ({ attendance }) => {
  const week = { Mo: [], Tu: [], We: [], Th: [], Fr: [] }

  if (attendance.fullday) {
    Object.entries(attendance.fullday).forEach(day => {
      const [dayOfWeek, type] = day
      if (type) week[dayOfWeek].push('Full Day')
    })
  }
  if (attendance.before) {
    Object.entries(attendance.before).forEach(day => {
      const [dayOfWeek, type] = day
      if (type) week[dayOfWeek].push('Before')
    })
  }
  if (attendance.after) {
    Object.entries(attendance.after).forEach(day => {
      const [dayOfWeek, type] = day
      if (type) week[dayOfWeek].push('After')
    })
  }

  if (attendance?.fullday && attendance?.before && attendance?.after) {
    Object.values(week).forEach(i => i.push('Full Day'))
  }

  // eslint-disable-next-line arrow-body-style
  const dayText = text => {
    if (!Array.isArray(text)) return text

    return text.join('/')
  }

  return (
    <>
      {Object.entries(week).map((day, idx) => (
        // const data = Object.entries(day)
        <div key={idx} tw="flex flex-col w-1/5 items-start">
          <span tw="block text-xs font-bold text-purple">{day[0]}</span>{' '}
          {day[1].length ? (
            <span className={textTransform(dayText(day[1]), 'kebabCase')}>{dayText(day[1])}</span>
          ) : (
            <span tw="text-gray-200 text-sm">None</span>
          )}
        </div>
      ))}
    </>
  )
}

const DayUi = ({ attendance }) => (
  <div tw="flex flex-wrap">
    <h1 tw="flex-grow w-full font-bold text-lg text-purple">Days Requested</h1>
    {Object.entries(attendance).map((day, idx) => {
      if (!day[1]) return null

      return (
        <span tw="inline-block pr-4" key={idx}>
          {format(parseISO(day[0]), 'MMM d, yyyy')}
        </span>
      )
    })}
  </div>
)

const WeekUi = ({ attendance }) => {
  console.log(attendance)
  return (
    <div tw="flex flex-wrap">
      <h1 tw="flex-grow w-full font-bold text-lg text-purple">Weeks Requested</h1>
      <div tw="flex flex-wrap">
        {Object.entries(weeksWithDaysSelected(attendance)).map((weeks, wID) => (
          <div tw="flex items-center space-x-1 mb-2 w-1/2" key={wID}>
            <span tw="pr-4">{weeks[0]}</span>
            {Object.entries(weeks[1]).map((dayOfWeek, dowID) => (
              <React.Fragment key={dowID}>
                {dayOfWeek[1] && (
                  <span tw="bg-purple text-white text-xs rounded-full px-2 py-1 capitalize w-8 text-center">
                    {dayOfWeek[0]}
                  </span>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

const renderAttendance = (ui, attendance) => {
  if (ui === 'startWeek') return <StartWeekUi attendance={attendance} />
  if (ui === 'day') return <DayUi attendance={attendance} />
  if (ui === 'week') return <WeekUi attendance={attendance} />

  return <div>Oops! No attendance data available</div>
}

export const Modal = ({ data, handleUpdate, handleClose }) => {
  // This fetches the child
  const { loading, error, response } = useFbFunc({
    endpoint: 'getRegistration',
    params: { childId: data.childId, program: data.program.name },
  })
  // This saves the status update
  const { setFetchOptions, loading: saving, error: saveError, response: saveResponse } = useFbFunc()
  const [status, setStatus] = useState()
  const wrapperRef = useRef(null)
  // useOutsideAlerter(wrapperRef)

  // Set the Current Status
  useEffect(() => {
    if (!response) return
    setStatus(response.data.enrollments[0].status)
  }, [response])

  useEffect(() => {
    if (saveResponse === undefined) return
    if (saveResponse.success) handleUpdate({ status })
  }, [handleUpdate, saveResponse, status])

  const { child, guardian, enrollments } = response?.data || {}

  const handleSave = e => {
    console.log('Saving Changes')
    setFetchOptions({
      endpoint: 'updateRegistrationStatus',
      params: { childId: child.id, status, program: enrollments[0].program },
    })
  }

  return (
    <div css={overlay} ref={wrapperRef}>
      <div tw="relative mx-auto shadow-xl bg-white w-full rounded max-w-screen-md">
        <button
          type="button"
          onClick={handleClose}
          tw="absolute right-0 top-0 text-4xl px-4 py-2 text-purple leading-none transform hocus:scale-110 transition duration-150 hocus:text-purple-light"
        >
          ×
        </button>
        {loading && (
          <div tw="px-8 p-6">
            <h1 tw="font-bold text-lg text-purple">Loading Information...</h1>
          </div>
        )}

        {response && (
          <>
            <div tw="px-8 pt-6">
              <h1 tw="font-bold text-lg text-purple">
                Child Information {child.currentlyEnrolled ? '– Current Child' : ''}
              </h1>
              <Row>
                <DataEl tw="font-bold w-1/3">
                  <span>Last Name</span> {child.lastName}
                </DataEl>
                <DataEl tw="font-bold w-1/3">
                  <span>First Name</span> {child.firstName}
                </DataEl>
                <DataEl tw="w-1/3">
                  <span>Date of Birth</span> {format(parseISO(child.birthday), 'MMM d, yyyy')} (
                  {getAge(child.birthday) > 1 ? `${getAge(child.birthday)} yrs` : `${getAge(child.birthday)} yr`} old)
                </DataEl>
              </Row>
              <Row>
                {enrollments[0].attendance?.startWeek && (
                  <DataEl tw="w-1/3">
                    <span>Start Week</span> {format(parseISO(enrollments[0].attendance.startWeek), 'MMM d, yyyy')}
                  </DataEl>
                )}
                <DataEl tw="w-1/3">
                  <span>First/Second Facility Choice</span> {enrollments[0].firstFacilityChoice} /{' '}
                  {enrollments[0].secondFacilityChoice}
                </DataEl>
                <DataEl tw="w-1/3">
                  <span>Age Group</span> {textTransform(enrollments[0].ageGroup, 'sentenceCase')}
                </DataEl>
              </Row>
              <Row tw="border-solid border-gray-200 border-t border-b py-4 space-x-4">
                {renderAttendance(data.program.ui, enrollments[0].attendance)}
              </Row>
              <h1 tw="font-bold text-lg text-purple">Parent/Guardian Information</h1>
              <Row>
                <DataEl tw="w-1/3">
                  <span>Last Name</span> {guardian.lastName}
                </DataEl>
                <DataEl tw="w-1/3">
                  <span>First Name</span> {guardian.firstName}
                </DataEl>
                <DataEl tw="w-1/3">
                  <span>Date of Birth</span> {format(parseISO(guardian.birthday), 'MMM d, yyyy')} (
                  {getAge(guardian.birthday) > 1
                    ? `${getAge(guardian.birthday)} yrs`
                    : `${getAge(guardian.birthday)} yr`}{' '}
                  old)
                </DataEl>
              </Row>
              <Row>
                <DataEl tw="w-2/3">
                  <span>Email</span> <a href={`mailto:${guardian.email}`}>{guardian.email}</a>
                </DataEl>
                <DataEl tw="w-1/3">
                  <span>Phone Number</span> <a href={`tel:${guardian.phone}`}>{guardian.phone}</a>
                </DataEl>
              </Row>
              <Row>
                <DataEl>
                  <span>Address</span> {guardian.address.formattedAddress}
                </DataEl>
              </Row>
            </div>
            <div tw="bg-gray-100 px-8 mt-4 rounded-b">
              <Row tw="items-center">
                <FormControl>
                  <label css={label} htmlFor="status">
                    Update Status
                  </label>
                  <Select id="status" name="status" value={status} onChange={e => setStatus(e.target.value)}>
                    <option value="New">New</option>
                    <option value="Placed">Placed</option>
                    <option value="Completed">Completed</option>
                    <option value="Follow Up">Follow Up</option>
                    <option value="Wait-listed">Wait-listed</option>
                    <option value="Rejected">Rejected</option>
                  </Select>
                </FormControl>
                <Button isPrimary isSmall isLoading={loading} onClick={handleSave}>
                  Save & Close
                </Button>
              </Row>
            </div>
          </>
        )}
        {/* <div tw="px-8 pt-6">
          <h1 tw="font-bold text-lg text-purple">
            Child Information {data.currentlyEnrolled ? '– Current Child' : ''}
          </h1>

          <Row>
            {data.attendance?.startWeek && (
              <DataEl tw="w-1/3">
                <span>Start Week</span> {formatAttendanceDate(data.attendance.startWeek)}
              </DataEl>
            )}
            <DataEl tw="w-1/3">
              <span>First/Second Facility Choice</span> {data.firstFacilityChoice} / {data.secondFacilityChoice}
            </DataEl>
            <DataEl tw="w-1/3">
              <span>Age Group</span> {data.programType}
            </DataEl>
          </Row>
          <Row tw="border-solid border-gray-200 border-t border-b py-4 space-x-4">
            {renderAttendance(data.program, data.attendance)}
          </Row>

          <h1 tw="font-bold text-lg text-purple">Parent/Guardian Information</h1>
          <Row>
            <DataEl tw="w-1/3">
              <span>Last Name</span> {data.lastName}
            </DataEl>
            <DataEl tw="w-1/3">
              <span>First Name</span> {data.firstName}
            </DataEl>
            <DataEl tw="w-1/3">
              <span>Date of Birth</span> {format(parseISO(data.birthday), 'MMM d, yyyy')} (
              {getAge(data.birthday) > 1 ? `${getAge(data.birthday)} yrs` : `${getAge(data.birthday)} yr`} old)
            </DataEl>
          </Row>
          <Row>
            <DataEl tw="w-2/3">
              <span>Email</span> <a href={`mailto:${data.email}`}>{data.email}</a>
            </DataEl>
            <DataEl tw="w-1/3">
              <span>Phone Number</span> <a href={`tel:${data.phone}`}>{data.phone}</a>
            </DataEl>
          </Row>
          <Row>
            <DataEl>
              <span>Address</span> {data.address.formattedAddress}
            </DataEl>
          </Row>
        </div>
        <div tw="bg-gray-100 px-8 mt-4 rounded-b">
          <Row tw="items-center">
            <FormControl>
              <label css={label} htmlFor="status">
                Update Status
              </label>
              <Select id="status" name="status" value={status} onChange={e => setStatus(e.target.value)}>
                <option value="New">New</option>
                <option value="Placed">Placed</option>
                <option value="Completed">Completed</option>
                <option value="Follow Up">Follow Up</option>
                <option value="Wait-listed">Wait-listed</option>
                <option value="Rejected">Rejected</option>
              </Select>
            </FormControl>
            <Button isPrimary isSmall isLoading={loading} onClick={handleSave}>
              Save & Close
            </Button>
          </Row>
        </div> */}
      </div>
    </div>
  )
}
