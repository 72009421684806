import React from 'react'
import tw, { styled, css } from 'twin.macro'

export const FormSection = styled.div`
  ${tw`p-4 sm:p-6`}
`

export const FormHeader = styled(FormSection)`
  ${tw`text-center`}

  h2 {
    ${tw`text-base md:text-xl lg:text-2xl font-bold text-purple text-center mb-4`}
  }

  p {
    ${tw`text-sm leading-normal`}
  }
`

export const FormContainer = styled.div`
  ${tw`border-solid border-t-2 border-b-2 border-purple-lightest bg-white divide-y divide-gray-200 sm:shadow-xl`}
`

export const FormFooter = styled(FormSection)`
  ${tw`flex justify-center sm:justify-end items-center lg:pt-8`}
`

export const FormControl = styled.div`
  ${tw`my-3 relative`}
`

export const Row = styled.div`
  ${tw`flex-wrap sm:flex-no-wrap`}
`

export const InputError = styled.div`
  ${tw`text-red text-xs font-bold block relative pt-2`}
`

export const label = tw`block text-sm font-semibold mb-2`

export const input = css`
  ${tw`h-12 bg-white w-full px-4 border border-gray-200 rounded-md shadow-sm appearance-none`}
  line-height: 1.2em;

  &:disabled {
    ${tw`cursor-not-allowed bg-gray-100`}
  }
`

export const Select = ({ children, ...props }) => (
  // console.log('Select Props', props)
  <div tw="relative">
    <select css={input} tw="pr-8" {...props}>
      {children}
    </select>
    <div tw="absolute flex inset-y-0 items-center px-3 right-0 text-gray-300 rounded-r pointer-events-none">
      <svg tw="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
)
