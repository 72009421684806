import React from 'react'
import { Router } from '@reach/router'
import { navigate } from 'gatsby'
import { useAuth } from '../context/auth-provider'

import Dashboard from '../app/dashboard'
import Accounts from '../app/admin/accounts'
import Registrations from '../app/registrations'
import NotFoundPage from './404'

function PrivateRoute({ component: RouteComponent, location, ...rest }) {
  const { user } = useAuth()

  React.useEffect(() => {
    if (!user && location.pathname !== `/`) {
      console.log('Redirecting to login')
      // If the user is not logged in, redirect to the home page.
      navigate(`/`)
    }
  }, [location.pathname, user])

  return <RouteComponent {...rest} />
}

function PublicRoute(props) {
  return <div>{props.children}</div>
}

const App = () => {
  const { initializing } = useAuth()

  if (initializing) {
    return <>Loading...</>
  }
  return (
    <Router>
      <PrivateRoute exact path="/app/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/app/registrations/:program" component={Registrations} />
      <PrivateRoute exact path="/app/accounts" component={Accounts} />
      <PublicRoute path="*">
        <NotFoundPage path="/404" />
      </PublicRoute>
    </Router>
  )
}

export default App
