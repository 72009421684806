import React, { useState, useRef } from "react"
import { AgGridReact } from "ag-grid-react"
import { FaCheckCircle } from "react-icons/fa"
import tw from "twin.macro"

import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { FormControl, label, input } from "./FormControls"
import { Modal } from "./Modal"
import { textTransform } from "../utils/textUtilities"

const getAgeGroupCounts = rowData => {
  const totals = rowData.reduce((acc, cur) => {
    // Watch out - will need logic for when we're dealing with change requests which should be the second array item
    acc[cur.enrollments[0].ageGroup] = acc[cur.enrollments[0].ageGroup] + 1 || 1
    return acc
  }, {})
  return totals
}

const RegistrationDataTable = ({ data, facilities, program }) => {
  const gridApi = useRef()
  const ageGroup = useRef("all")
  const [modalData, setModalData] = useState()
  const [ageGroupTotals, setAgeGroupTotals] = useState({})

  // console.log(`program filter is: ${ageGroup.current}`)
  // console.log('Passed Program', program)

  const [state] = useState({
    columnDefs: [
      {
        headerName: "Child Name",
        valueGetter(params) {
          return `${params.data.childLastName}, ${params.data.childFirstName}`
        },
      },
      {
        headerName: "Parent Name",
        valueGetter(params) {
          return `${params.data.guardianLastName}, ${params.data.guardianFirstName}`
        },
      },
      {
        headerName: "Facility Choice",
        valueGetter(params) {
          return params.data.enrollments[0].firstFacilityChoice
        },
        hide: facilities.length === 1,
      },

      {
        headerName: "Date Entered",
        width: 170,
        valueGetter(params) {
          return params.data.enrollments[0].createdAt
        },
        // valueFormatter: dateFormatter,
        sort: "desc",
      },
      {
        headerName: "Start Week",
        width: 130,
        valueGetter(params) {
          if (params.data.enrollments[0].startWeek)
            return params.data.enrollments[0].startWeek
        },
        hide: !data[0].enrollments[0].startWeek,
      },
      {
        headerName: "Current Child",
        field: "currentlyEnrolled",
        width: 110,
        cellStyle: { "text-align": "center" },
        valueFormatter(params) {
          return params.value ? "Yes" : ""
        },
      },
      {
        headerName: "Status",
        width: 90,
        valueGetter(params) {
          return params.data.enrollments[0].status
            ? params.data.enrollments[0].status
            : "New"
        },
        // cellClassRules: {
        //   'pill pill-green': function (params) {
        //     return params.value === undefined
        //   },
        // },
      },
      {
        headerName: "Fall/22",
        width: 90,
        cellRenderer: "iconCellRenderer",
        // cellRenderer(params) {
        //   // console.log('zuh', params)
        //   return params.data.enrollments[0].confirmedAt ? '<><FaCheckCircle />tets</FaCheckCircle>' : null
        // },
        cellStyle: { "text-align": "center" },
      },
    ],
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,
      autoHeight: true,
    },
    multiSortKey: "ctrl",
    sortingOrder: ["desc", "asc", null],
  })

  const IconCellRenderer = props =>
    props.data.enrollments[0].confirmedAt ? (
      <FaCheckCircle tw="text-green mt-2" size={18} />
    ) : null

  const onGridReady = params => {
    gridApi.current = params.api
    setAgeGroupTotals(getAgeGroupCounts(data))
    // Fit columns
    gridApi.current.sizeColumnsToFit()
  }

  const goToFirstPage = () => {
    gridApi.current.ensureIndexVisible(0, "top")
  }

  const externalFilterChanged = e => {
    e.target.checked = true
    ageGroup.current = e.target.value
    console.log("Changing filter to", ageGroup.current)
    gridApi.current.onFilterChanged()
    goToFirstPage()
  }

  const isExternalFilterPresent = () => ageGroup.current !== "all"

  const doesExternalFilterPass = node => {
    switch (ageGroup.current) {
      case "infant":
        return node.data.enrollments[0].ageGroup === "infant"
      case "toddler":
        return node.data.enrollments[0].ageGroup === "toddler"
      case "preschool":
        return node.data.enrollments[0].ageGroup === "preschool"
      case "kindergarten":
        return node.data.enrollments[0].ageGroup === "kindergarten"
      case "schoolAge":
        return node.data.enrollments[0].ageGroup === "schoolAge"
      default:
        return true
    }
  }

  const onFilterTextBoxChanged = e => {
    gridApi.current.setQuickFilter(e.target.value)
  }

  const handleRowSelection = e => {
    const selectedRows = gridApi.current.getSelectedRows()
    if (selectedRows.length === 1)
      return setModalData({ childId: selectedRows[0].childId, program })

    return setModalData(null)
  }

  const handleModalClose = () => {
    setModalData(null)
  }

  const handleUpdate = dataToUpdate => {
    const itemsToUpdate = []
    gridApi.current.forEachNodeAfterFilterAndSort(function (rowNode, index) {
      if (!rowNode.selected) {
        return
      }
      const { data } = rowNode
      data.enrollments[0].status = dataToUpdate.status
      itemsToUpdate.push(data)
    })
    const res = gridApi.current.applyTransaction({ update: itemsToUpdate })

    setModalData(null)
  }

  // return data.map((record, idx) => <span key={idx}>{record.childFirstName}</span>)
  return (
    <div className="ag-theme-alpine" tw="py-4" css={{ height: "85%" }}>
      <nav tw="flex space-x-4">
        <FormControl>
          <label css={label} htmlFor="all">
            <input
              type="radio"
              name="filter"
              id="all"
              value="all"
              onChange={externalFilterChanged}
            />
            <span tw="ml-2">
              All <i tw="text-xs not-italic">({data.length})</i>
            </span>
          </label>
        </FormControl>
        {program?.ageGroups?.map(ageGroup => (
          <FormControl key={ageGroup}>
            <label css={label} htmlFor={ageGroup}>
              <input
                type="radio"
                name="filter"
                id={ageGroup}
                value={ageGroup}
                onChange={externalFilterChanged}
              />
              <span tw="ml-2">
                {textTransform(ageGroup, "sentenceCase")}{" "}
                <i tw="text-xs not-italic">
                  ({ageGroupTotals[ageGroup] ? ageGroupTotals[ageGroup] : 0})
                </i>
              </span>
            </label>
          </FormControl>
        ))}
      </nav>
      <FormControl>
        <input
          css={input}
          tw="text-lg"
          type="text"
          id="filter-text-box"
          placeholder="Search..."
          onInput={onFilterTextBoxChanged}
        />
      </FormControl>
      <AgGridReact
        columnDefs={state.columnDefs}
        defaultColDef={state.defaultColDef}
        multiSortKey={state.multiSortKey}
        sortingOrder={state.sortingOrder}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        rowSelection="single"
        onSelectionChanged={handleRowSelection}
        onGridReady={onGridReady}
        rowData={data}
        animateRows
        frameworkComponents={{
          iconCellRenderer: IconCellRenderer,
        }}
      />
      {modalData && (
        <Modal
          data={modalData}
          handleUpdate={handleUpdate}
          handleClose={handleModalClose}
        />
      )}
    </div>
  )
}

export default RegistrationDataTable
