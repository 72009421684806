import React, { useState, useEffect, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import { useAuth } from '../context/auth-provider'
import useFbFunc from '../hooks/useFbFunc'

import { Container } from '../components/styles'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import RegistrationDataTable from '../components/RegistrationDataTable'

const Registrations = ({ program, location, ...props }) => {
  const { user } = useAuth()
  const { loading, error, response } = useFbFunc({
    endpoint: 'getRegistrations',
    params: { programs: program, facilityChoice: 'first' },
  })

  const { loading: programLoading, response: programResponse } = useFbFunc({
    endpoint: 'getPrograms',
    params: { programs: `${program}`, active: true },
  })

  // Is admin
  useEffect(() => {
    if (!user) return
    user
      .getIdTokenResult(true)
      .then(idTokenResult => {
        console.log('Has Admin Claim?', idTokenResult.claims.admin)
      })
      .catch(error => {
        console.log(error)
      })
  }, [user])

  return (
    <Layout>
      <SEO title="Registrations" />
      <Container tw="h-full">
        <Link to="/app/dashboard" tw="text-purple mb-4 block">
          &#8592; Back to program list
        </Link>

        {(loading || programLoading) && <div tw="mt-5">Loading...</div>}
        {programResponse?.count && response?.count && (
          <>
            <h1 tw="font-bold text-xl text-purple">
              {`${programResponse?.data?.[0].displayName} `}Registrations for {response?.facilities?.join(', ')}
            </h1>
            {response?.count && (
              <RegistrationDataTable
                data={response?.data}
                facilities={response?.facilities}
                program={programResponse?.data[0]}
              />
            )}
          </>
        )}
      </Container>
    </Layout>
  )
}

export default Registrations
