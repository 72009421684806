import React, { useState, useEffect, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import { useAuth } from '../context/auth-provider'
import useFbFunc from '../hooks/useFbFunc'

import { Container } from '../components/styles'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const Dashboard = () => {
  const { user } = useAuth()
  const { setFetchOptions, loading, error, response } = useFbFunc({
    endpoint: 'getPrograms',
    params: { active: true },
  })

  // Is admin
  useEffect(() => {
    if (!user) return
    user
      .getIdTokenResult(true)
      .then(idTokenResult => {
        console.log('Has Admin Claim?', idTokenResult.claims.admin)
      })
      .catch(error => {
        console.log(error)
      })
  }, [user])

  return (
    <Layout>
      <SEO title="Dashboard" />
      <Container tw="h-full">
        <div>
          <h2 tw="text-lg font-bold mb-4 block">Choose a program to view registrations</h2>
          {loading && <>Loading...</>}
          <div tw="lg:flex flex-wrap lg:space-x-2 space-y-2 lg:space-y-0">
            {response?.data &&
              response.data.map((program, pidx) => (
                <div key={pidx} tw="flex-grow">
                  <Link
                    to={`/app/registrations/${program.name}`}
                    tw="flex justify-center items-center block border border-purple-lightest p-4 font-bold hover:bg-purple hover:text-white rounded "
                  >
                    <h3>{program.displayName}</h3>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Dashboard
