const sentenceCase = text => {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function textTransform(text, transformType) {
  switch (transformType) {
    case 'lowercase':
      return text.toLowerCase()
    case 'uppercase':
      return text.toUpperCase()
    case 'kebabCase':
      return text
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('-')
    case 'title':
      return text
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
        .join(' ')
    case 'capitalize':
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
    case 'sentenceCase':
      return sentenceCase(text)
    case 'initial':
      return text
    default:
      return text
  }
}
