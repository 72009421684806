import { useState, useEffect, useRef } from 'react'
import firebase from 'gatsby-plugin-firebase'

// Context
import { useMessage } from '../context/message-provider'

const useFbFunc = options => {
  const { addMessage } = useMessage()
  const [fetchOptions, setFetchOptions] = useState(() => ({ endpoint: null, params: {}, ...options }))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [response, setResponse] = useState()

  useEffect(() => {
    if (error || !fetchOptions.endpoint) return
    setLoading(true)

    const fetchData = async () => {
      console.log(`Fetching from Firebase function ${fetchOptions.endpoint}`)
      try {
        const func = firebase.functions().httpsCallable(fetchOptions.endpoint)

        // Add a pass vars in the func()
        const res = await func(fetchOptions.params)

        setLoading(false)
        setResponse(res.data)
      } catch (error) {
        console.error(`🤦‍♂️ Ding! Issue with ${fetchOptions.endpoint}`, error)
        setLoading(false)
        setError(error)
        addMessage(`Oops! Something went wrong. ${error.message}`)
      }
    }

    fetchData()
  }, [addMessage, error, fetchOptions.endpoint, fetchOptions.params])

  return { setFetchOptions, loading, error, response }
}

export default useFbFunc
