import React from 'react'
import { Link } from 'gatsby'
import tw, { css } from 'twin.macro'
import Loader from '../images/oval.svg'

const LoaderIcon = ({ isLoading }) => {
  if (!isLoading) return null
  return <Loader tw="fill-current w-4 h-4 ml-2 -mr-3" />
}

const Button = ({ isPrimary, isClear, isSmall, isInternal, isLoading, children, ...rest }) => {
  // The base button styles added with the tw macro
  const style = css`
    ${tw`relative inline-flex w-auto h-0 items-center md:text-base font-bold p-5 md:px-8 md:py-6 rounded
  transform hocus:scale-105 transition duration-150
  hocus:bg-purple-light focus:outline-none`}

    &:disabled {
      ${tw`cursor-not-allowed hocus:scale-100 hocus:bg-purple`}
    }

    /* Use props to conditionally style your components */
    ${isPrimary && tw`bg-purple text-white border-purple`}

    ${isClear && tw`hocus:bg-transparent hocus:text-purple-light`}

    ${isSmall && tw`md:text-sm md:px-6`}
  `

  // const style = { ...base, ...primary, ...clear}

  let Component = 'button'
  if (rest.href) Component = 'a'
  if (rest.to) Component = Link

  const attributes = {
    css: style,
    disabled: isLoading,
    ...rest,
  }

  return (
    <Component {...attributes}>
      <span>{!isLoading ? children : 'Saving'}</span>
      <LoaderIcon isLoading={isLoading} />
    </Component>
  )
}

export default Button
